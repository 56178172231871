import { useAccount } from "wagmi";
import { Identity } from "@semaphore-protocol/identity";

export const useIdentity = () => {
  const { address, isConnected } = useAccount();

  if (!address || !isConnected) return null;
  const user = new Identity(address as string);
  return user;
};
