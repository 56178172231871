import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Identity } from "@semaphore-protocol/identity";
import { joinGroup } from "../lib/api";
import { RootState, useAppDispatch } from "../state/store";
import { useHandleCommunityAction } from "./useHandleCommunityAction";
import { useAccount } from "wagmi";
import { polygonMumbai } from "wagmi/chains";
import { prependUser, removeUser } from "../state/slice";
import { jsonRPCProvider } from "../constant/const";

export const useJoinCommunity = () => {
  const provider = jsonRPCProvider;
  const { address, isConnected } = useAccount();
  const store = useSelector((state: RootState) => state);
  const handleCommunityAction = useHandleCommunityAction();
  const dispatch = useAppDispatch();
  return useCallback(
    async (
      groupName: string,
      groupId: string | number,
      successCallback?: () => void
    ) => {
      if (!isConnected) return;

      const actionFn = async () => {
        const username = "anon";
        const freshUser = new Identity(`${address}_${groupId}_${username}`);
        try {
          // Attempt to join the community
          return await joinGroup(
            groupId.toString(),
            freshUser.getCommitment().toString(),
            username
          ).then((d) => {
            // dispatch prepend user
            dispatch(
              prependUser({
                groupId: +groupId.toString(),
                name: username,
                identityCommitment: freshUser.getCommitment().toString(),
              })
            );
            if (successCallback) {
              successCallback();
            }
            return d;
          })
        } catch (error) {
          // If the transaction fails, roll back the state update
          dispatch(
            removeUser({
              groupId: +groupId.toString(),
              identityCommitment: freshUser.getCommitment().toString(),
            })
          );
        }
      };

      await handleCommunityAction(
        actionFn,
        [],
        `Successfully joined ${groupName}`,
        successCallback
      );
    },
    [isConnected, address, provider, store, handleCommunityAction, dispatch] // Add dispatch to the dependencies array
  );
};
