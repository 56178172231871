import { Identity } from "@semaphore-protocol/identity";
import { useEffect } from "react"
import { useAccount, useConnect, useNetwork } from "wagmi";
import { UnirepUser } from "../lib/unirep";
import { attesterAddress } from "../constant/const";
import { userUnirepSignUp } from "../lib/api";

export const useUnirepSignUp = ({name, groupId}) => {
  const { address } = useAccount();
  useEffect(() => {
    if (!address || !name || !groupId) return;
    const generatedIdentity = new Identity(`${address}_${groupId}_${name}`)

    console.log(generatedIdentity);

    new UnirepUser(generatedIdentity);

    // signUp(generatedIdentity);
  }, [address, groupId, name])

  const signUp = async (generatedIdentity) => {
    try {
      const user = new UnirepUser(generatedIdentity as Identity)
      const res = await user.signup();
      console.log(res)
    } catch (error) {
      console.log(error);
    }
  }
}