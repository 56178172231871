import { createSlice } from '@reduxjs/toolkit'
import { Identity } from '@semaphore-protocol/identity'
import { groupBy } from 'lodash'
import { Community, User } from '../lib/model'
import { RootState } from './store'

type SliceState = {
    communities: Community[],
    users: User[],
    usersGrouped: {[key: string]: User[]}
}
const initialState: SliceState = {
    communities: [],
    users: [],
    usersGrouped: {}
}

export const communitySlice = createSlice({
    name: 'community',
    initialState,
    reducers: {
        setCommunities: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.communities = action.payload
        },
        prependCommunity: (state, action) => {
            const groupId = typeof(action.payload.groupId) === 'object' ? action.payload.groupId?.toNumber() : action.payload.groupId;
            state.communities.unshift(action.payload);
            state.usersGrouped[groupId] = [];
        },

        setUsers: (state, action) => {
            state.users = action.payload;
            state.usersGrouped = groupBy(action.payload, 'groupId');
        },

        prependUser: (state, action) => {
            const groupId = typeof(action.payload.groupId) === 'object' ? action.payload.groupId?.toNumber() : action.payload.groupId;
            state.users.unshift(action.payload);
            if (!state.usersGrouped[groupId]) {
                state.usersGrouped[groupId] = [];
            }
            state.usersGrouped[groupId] = [action.payload, ...state.usersGrouped[groupId]];
            const i = state.communities.findIndex(c => +c.groupId === +groupId);
            state.communities[i].userCount += 1;
        },

        removeUser: (state, action) => {
            const groupId = typeof(action.payload.groupId) === 'object' ? action.payload.groupId?.toNumber() : action.payload.groupId;

            // Remove user from 'users' array
            state.users = state.users.filter(user => user.identityCommitment !== action.payload.identityCommitment);

            // Remove user from 'usersGrouped' object
            if (state.usersGrouped[groupId]) {
                state.usersGrouped[groupId] = state.usersGrouped[groupId].filter(user => user.identityCommitment !== action.payload.identityCommitment);
            }

            // Decrement user count in 'communities'
            const i = state.communities.findIndex(c => +c.groupId === +groupId);
            if (state.communities[i].userCount > 0) {
                state.communities[i].userCount -= 1;
            }
        },

        removeCommunity: (state, action) => {
            const i = state.communities.findIndex(c => +c.id === +action.payload.groupId);
            state.communities.splice(i, 1);
        }

    },
})

export const { setCommunities, prependCommunity, removeCommunity, setUsers, prependUser, removeUser } = communitySlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        // dispatch(incrementByAmount(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCommunities = (state: RootState) => state.community.communities;
export const selectCommunityById = (state: RootState, id) => state.community.communities.find(c => c.groupId === id);

/** User related selectors */
export const selectUsers = (state: RootState) => state.community.users;
export const selectUserById = (state: RootState, id) => state.community.users.find(c => c.id === id);
export const seletcGroupedUser = (state: RootState, id) => state.community.usersGrouped[id];
export const seletcGroupedUserLength = (state: RootState, id) => state.community.usersGrouped[id].length;
export const selectIfUserHasjoined = (state: RootState, userAddress, communityId) => {
    const uJoined = state.community.usersGrouped[communityId]?.find(u => {
        const generatedIdentity = new Identity(`${userAddress}_${communityId}_${u.name}`)
        const userCommitment = generatedIdentity.getCommitment().toString();
        // console.table([u?.groupId, communityId, u?.identityCommitment, userCommitment])
        return u?.groupId === communityId && u?.identityCommitment === userCommitment
    });
    return uJoined
}

export default communitySlice.reducer
