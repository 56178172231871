import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import "./CreateGroupFormUI.css";
import { PictureUpload } from "./PictureUpload";
import { uploadAndCacheImages } from "../utils/communityUtils";
import { useIdentity } from "../hooks/useIdentity";
import { FiEdit } from "react-icons/fi";
import { useLoaderContext } from "../context/LoaderContext";
import { useFetchCommunities } from "../hooks/useFetchCommunities";
import { ToolTip } from "./HOC/ToolTip";
import { useTranslation } from "react-i18next";
import { createNote } from "../lib/utils";

function EditGroupModal({ community, hidden = undefined }) {
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [isEditGroupOpen, setIsEditGroupOpen] = useState(false);
  const { isLoading, setIsLoading } = useLoaderContext();
  const fetchCommunities = useFetchCommunities(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEditGroupOpen) return;
    if (community.banner) {
      fetch("https://ipfs.io/ipfs/" + community.banner)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "banner", { type: blob.type });
          setBannerFile(file);
        });
    }

    if (community.logo) {
      fetch("https://ipfs.io/ipfs/" + community.logo)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "logo", { type: blob.type });
          setLogoFile(file);
        });
    }
  }, [isEditGroupOpen]);

  const identity = useIdentity();

  const isOwner = useMemo(async () => {
    const note = await createNote(identity);
    return community.note === note.toString();
  }, [community?.ownerIdentity, identity]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (e.target.name === "banner") {
      setBannerFile(file);
    } else {
      setLogoFile(file);
    }
  };

  const submit = async () => {
    setIsLoading(true);
    await uploadAndCacheImages({
      bannerFile,
      logoFile,
      groupId: community.groupId,
    });
    await fetchCommunities();
    setIsEditGroupOpen(false);
    setIsLoading(false);
  };

  const isSubmitDisabled = !bannerFile && !logoFile;

  const { logoUrl, bannerUrl } = useMemo(() => {
    return {
      logoUrl: logoFile ? URL.createObjectURL(logoFile) : "",
      bannerUrl: bannerFile ? URL.createObjectURL(bannerFile) : "",
    };
  }, [logoFile, bannerFile]);

  return (
    <>
      <ToolTip
        type={"primary"}
        title={t("toolTip.editCommunity.title")}
        message={t("toolTip.editCommunity.message")}
      >
        <IconButton
          id={"edit-community-button"}
          position={"absolute"}
          right={0}
          variant={"ghost"}
          _hover={{ bg: "#8F15AB7F", color: "white" }}
          style={{ zIndex: 100 }}
          hidden={!isOwner || hidden}
          onClick={() => setIsEditGroupOpen(true)}
          icon={<FiEdit />}
          aria-label={"edit community"}
        />
      </ToolTip>

      <Modal
        isOpen={isEditGroupOpen}
        onClose={() => setIsEditGroupOpen(false)}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader background={"primary.500"} color={'white'} borderTopRadius={5}>
            Edit Community Content <ModalCloseButton mt={2} />
          </ModalHeader>
          <ModalBody my={4}>
            <Flex gap={4} direction={'column'}>
              <PictureUpload
                uploadedImageUrl={bannerUrl}
                displayName={t("banner")}
                name={"banner"}
                setImageFileState={setBannerFile}
              />

              <PictureUpload
                uploadedImageUrl={logoUrl}
                displayName={t("logo")}
                name={"logo"}
                setImageFileState={setLogoFile}
              />
            </Flex>
          </ModalBody>
          <hr />

          <ModalFooter borderBottomRadius={5}>
            <Button mr={3} onClick={() => setIsEditGroupOpen(false)}>
              {t("button.close")}
            </Button>
            <Button
              _hover={{ bg: "primary.700" }}
              disabled={isSubmitDisabled}
              onClick={submit}
            >
              {t("button.upload")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditGroupModal;
