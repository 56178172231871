import { CardBody, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useLocalCommunity } from "./CommunityCard";
import { supportedChains } from "../../constant/const";

export const CommunityCardBody = () => {
    const community = useLocalCommunity();

    return (
        <CardBody className={"community-card-body"}>
            <Text
                mt={14}
                fontWeight={"bold"}
                fontSize={20}
                overflowWrap={"anywhere"}
            >
                {community.name}
            </Text>
            <Text fontSize={"small"} >{`${community.userCount ?? 0} Members`}</Text>
            {community?.requirements?.map((r) => (
                <Flex mt={0} key={r?.tokenAddress}>
                    <Text fontSize={"small"} >
                        {`${r?.symbol}`} {" - "} { Number(r?.minAmount) / (10 ** r?.decimals)}
                    </Text>
                </Flex>
            ))}
            <Text fontSize={'small'}>{supportedChains[community.chainId].name}</Text>
        </CardBody>
    );
};
