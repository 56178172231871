import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Community } from "../../lib/model";
import { Footer } from "./Footer";
import "./CommunityCard.css";
import { Card, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import EditGroupModal from "../EditGroupModal";
import { motion } from "framer-motion";
import { FlexProps } from "@chakra-ui/layout";
import RemoveGroup from "../RemoveGroup";
import { useRemoveGroup } from "../../hooks/useRemoveGroup";
import { CommunityCardHeader } from "./CommunityCardHeader";
import { CommunityCardBody } from "./CommunityCardBody";
// local community context
export const CommunityContext = React.createContext<Community | null>(null);
const MotionFlex = motion(Flex);

export const useLocalCommunity = () => {
  const community = React.useContext(CommunityContext);
  if (!community) throw new Error("CommunityContext not found");
  return community;
};
export const CommunityCard = ({
  community,
  index,
  isAdmin = false,
  ...props
}: {
  community: Community;
  index: number;
  isAdmin?: boolean
} & FlexProps) => {
  const [isEditGroupVisible, setIsEditGroupVisible] = React.useState(false);
  const [isDeleteGroupVisible, setIsDeleteGroupVisible] = React.useState(false);

  const { write } = useRemoveGroup(community?.id);

  const cardAnimation = {
    hidden: { opacity: 0, scale: 0.9, y: 30 },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay:  0.1,
        // delay should get faster as the index increases
        delayChildren: index * 0.8,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      y: 30,
      transition: {
        duration: 0.4,
        delay:  0.1,
      },
    },
  };
  // new ref for modal
  const cardRef = useRef(null);

  useEffect(() => {
    if (!cardRef?.current) return () => {};

    if (!cardRef?.current?.hasEventListeners?.("mouseenter")) {
      console.log("adding event listeners");

      cardRef.current.addEventListener("mouseenter", () => {
        setIsEditGroupVisible(true);
        setIsDeleteGroupVisible(true);
      });
      cardRef.current.addEventListener("mouseleave", () => {
        setIsEditGroupVisible(false);
        setIsDeleteGroupVisible(false);
      });
    }

    return () => {
      console.log("removing event listeners");
      cardRef?.current?.removeEventListener?.("mouseenter", () => {});
      cardRef?.current?.removeEventListener?.("mouseleave", () => {});
    };
  }, [cardRef]);

  if (!community || !community?.id)
    return (
      <div className={"community-card-container"}>
        {/*<Skeleton height="220px" minWidth={225} />*/}
        {/*<SkeletonText mt={7} noOfLines={4} spacing="4" />*/}
      </div>
    );

  return (
    <MotionFlex
      flexDirection={"column"}
      ref={cardRef}
      variants={cardAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      {...props}
    >
      <CommunityContext.Provider value={community}>
        <div className={"community-card-container"}>
          <Card className={"community-card-container"}>
            <EditGroupModal
              community={community}
              hidden={!isEditGroupVisible}
            />
            {isAdmin && <RemoveGroup onClick={() => write({recklesslySetUnpreparedArgs: [community.id],})} hidden={!isDeleteGroupVisible} />}

            <Link to={`/communities/${community?.groupId}`}>
              <CommunityCardHeader />
              <CommunityCardBody />
            </Link>
            <Footer />
          </Card>
        </div>
      </CommunityContext.Provider>
    </MotionFlex>
  );
};
